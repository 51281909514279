import React from "react";
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography
} from "@material-ui/core";
import {arrayFilter, choiceImage, isResponsive} from "../../helpers/Tools";
import BreadCrumbs from "../../components/BreadCrumbs";
import FlatList from 'flatlist-react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import OpenItem from "../../helpers/OpenItem";
import {ShapperButton} from "../../components/ShapperButton/ShapperButton";

class Category extends React.Component {

    render() {
        const dyn_string = this.props.t
        let list = arrayFilter(this.props.nodes, 'is_visible', '1');

        if (isResponsive()) {
            return (
                    <ul style={{
                        padding: 0,
                        listStyle: "none",
                        gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                        paddingInlineStart: "20px",
                        paddingInlineEnd: "20px",
                    }}>
                        <FlatList
                            list={list}
                            renderItem={this.renderCategory}
                            renderWhenEmpty={() => <h4
                                style={{marginTop: "70px", textAlign: "center"}}>{dyn_string('g_label_no_result')}</h4>}
                            display={{
                                grid: true,
                                gridGap: "20px",
                                minColumnWidth: "200px",
                            }}
                            style={{listStyle: "none"}}
                        />
                    </ul>
            );
        } else {
            return (
                <div>
                    <BreadCrumbs id={this.props.dataPage.id} style={{marginBottom: "20px"}}/>
                    <ul style={{
                        gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
                        paddingInlineStart: "20px",
                        paddingInlineEnd: "20px",
                    }}>
                        <FlatList
                            list={list}
                            renderItem={this.renderCategory}
                            renderWhenEmpty={() => <h4
                                style={{marginTop: "70px", textAlign: "center"}}>{dyn_string('g_label_no_result')}</h4>}
                            display={{
                                grid: true,
                                gridGap: "40px",
                                minColumnWidth: "350px",
                            }}
                        />
                    </ul>
                </div>
            );
        }
    }

    renderCategory = (tile, key) => {
        let cat_style;
        if (isResponsive()) {
            cat_style = {}
        } else {
            cat_style = {}
        }

        let isListSubtitle = this.props.dataPage.category_type === "list_subtitle";

        return (
            <OpenItem
                key={key}
                contentToRedirect={tile.id}
                isFor={'category'}
            >

                <Card>
                    <CardActionArea style={{textAlign: "center"}}>
                        <CardMedia
                            component="img"
                            alt={tile.title}
                            width="100%"
                            image={choiceImage(tile)}
                            title={tile.title}
                            style={{objectFit: "scale-down", height: "180px"}}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="h4" style={{whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis"}}>
                                {tile.title}
                            </Typography>
                            <ShapperButton size="small">
                                {this.props.t('category_btn_see_more')}
                            </ShapperButton>
                        </CardContent>
                    </CardActionArea>
                </Card>


            </OpenItem>
        );
    }
}

const mapStateToProps = (props) => ({
    styles: props.style
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps()))(Category);
