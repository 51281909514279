import React from "react";
import {Image} from "react-bootstrap";
import OpenItem from "../../../helpers/OpenItem";
import "./OneDisplay.css"
import {constructText, constructObjectFitPosition} from "./OneDisplay.functions";
import {buildShapperPath} from "../../../helpers/Tools";

export class OneDisplay extends React.Component {

    render() {
        let text = constructText(this.props);
        let backgroundColor = this.props.data.color ? this.props.data.color : "";

        let objectFitPosition = constructObjectFitPosition(this.props)
        let objectFit = objectFitPosition.fit;
        let objectPosition = objectFitPosition.position;

        let rotation = this.props.data.rotation ? "rotate(" + this.props.data.rotation + "deg)" : "";
        let opacity = this.props.data.opacity ? this.props.data.opacity + "%" : "";
        let borderRadius = this.props.data.corner_radius ? this.props.data.corner_radius : "";
        let fontFamily = this.props.data.text_font ? this.props.data.text_font : "";
        let fontSize = this.props.data.text_size ? this.props.data.text_size : "";
        let posText = this.props.data.text_align ? this.props.data.text_align : "";
        let styleText = this.props.data.hasOptionShadow ? {backgroundColor:this.props.data.text_shadow_color, opacity:0.5} : {opacity:1};

        if (this.props.typeLink === "ExternalLink") {
            if (this.props.data.image) {
                return (
                    <a target="_blank" rel="noopener noreferrer" href={this.props.link}>
                        <Image
                            style={{
                                objectFit: "scale-down",
                                position: "absolute",
                                top: this.props.y,
                                left: this.props.x,
                            }}
                            width={this.props.width}
                            height={this.props.height}
                            src={this.props.src}
                        />
                    </a>
                );
            }
            else {
                return (
                    <a target="_blank" rel="noopener noreferrer" href={this.props.link}>
                        <div style={{
                            backgroundColor: this.props.data.color,
                            fontSize: fontSize,
                            fontFamily: fontFamily,
                            width: this.props.width,
                            height: this.props.height,
                            top: this.props.y,
                            left: this.props.x,
                            position: "absolute",
                            textAlign: this.props.data.text_align,
                            color: this.props.data.text_color,
                            borderRadius: borderRadius,
                            opacity: opacity,
                            transform: rotation,
                            objectFit: objectFit,
                            objectPosition: objectPosition,
                        }}>
                            <div className={posText.replace(/[-]/g, "")}  style={styleText}> {text}</div>
                        </div>
                    </a>
                );
            }
        }
        else if (this.props.typeLink === "AppLink") {
            if (this.props.data.image) {
                console.log("IMAGE + APP LINK")
                return (
                    <div style={{
                        fontSize: fontSize,
                        fontFamily: fontFamily,
                        width: this.props.width,
                        height: this.props.height,
                        top: this.props.y,
                        left: this.props.x,
                        position: "absolute",
                        textAlign: this.props.data.text_align,
                        color: this.props.data.text_color
                    }}>
                        <div className={posText.replace(/[-]/g, "")}  style={styleText} > {text}</div>
                        <OpenItem
                            contentToRedirect={this.props.link}
                        >
                            <Image
                                style={{
                                    borderRadius: borderRadius,
                                    opacity: opacity,
                                    backgroundColor: backgroundColor,
                                    transform: rotation,
                                    objectFit: objectFit,
                                    objectPosition: objectPosition,
                                }}
                                width={this.props.width}
                                height={this.props.height}
                                src={this.props.src}
                            />

                        </OpenItem>
                    </div>
                );
            } else if (this.props.data.content) {
                console.log('VIDEO');
                return (
                    <OpenItem
                        contentToRedirect={this.props.link}
                    >
                        <div style={{
                            backgroundColor: this.props.data.color,
                            fontSize: fontSize,
                            fontFamily: fontFamily,
                            width: this.props.width,
                            height: this.props.height,
                            top: this.props.y,
                            left: this.props.x,
                            position: "absolute",
                            textAlign: this.props.data.text_align,
                            color: this.props.data.text_color,
                            borderRadius: borderRadius,
                            opacity: opacity,
                            transform: rotation,
                            objectFit: objectFit,
                            objectPosition: objectPosition,
                        }}>
                            <iframe
                                title="youtube"
                                width="100%"
                                height="100%"
                                src={
                                    this.props.data.content[0].url +
                                    "&controls=" +
                                    this.props.data.content[0].hasOptionTimeLine +
                                    "&loop=" +
                                    this.props.data.content[0].hasOptionLoop
                                }

                                /*src={
                                    this.props.data.content[0].url +
                                    "&autoplay=1" +
                                    "&controls=" +
                                    this.props.data.content[0].hasOptionTimeLine +
                                    "&loop=" +
                                    this.props.data.content[0].hasOptionLoop
                                }*/

                                frameBorder="0"
                                //allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    </OpenItem>)
            } else {
                return (
                    <OpenItem
                        contentToRedirect={this.props.link}
                    >
                        <div style={{
                            backgroundColor: this.props.data.color,
                            fontSize: fontSize,
                            fontFamily: fontFamily,
                            width: this.props.width,
                            height: this.props.height,
                            top: this.props.y,
                            left: this.props.x,
                            position: "absolute",
                            textAlign: this.props.data.text_align,
                            color: this.props.data.text_color,
                            borderRadius: borderRadius,
                            opacity: opacity,
                            transform: rotation,
                            objectFit: objectFit,
                            objectPosition: objectPosition,
                        }}>
                            <div className={posText.replace(/[-]/g, "")}  style={styleText}> {text}</div>
                        </div>
                    </OpenItem>)
            }
        }
        else {
            if (this.props.data.image) {
                return (
                    <div style={{
                        fontSize: fontSize,
                        fontFamily: fontFamily,
                        width: this.props.width,
                        height: this.props.height,
                        top: this.props.y,
                        left: this.props.x,
                        position: "absolute",
                        textAlign: this.props.data.text_align,
                        color: this.props.data.text_color
                    }}
                    >
                        <Image
                            style={{
                                borderRadius: borderRadius,
                                opacity: opacity,
                                backgroundColor: backgroundColor,
                                transform: rotation,
                                objectFit: objectFit,
                                objectPosition: objectPosition,
                            }}
                            width={this.props.width}
                            height={this.props.height}
                            src={this.props.src}
                        />
                        <div className={posText.replace(/[-]/g, "")}  style={styleText}> {text}</div>

                    </div>
                );
            }
            else if (text) {
                return (
                    <div style={{
                        fontSize: fontSize,
                        transform: rotation,
                        borderRadius: borderRadius,
                        opacity: opacity,
                        backgroundColor: backgroundColor,
                        fontFamily: fontFamily,
                        width: this.props.width,
                        height: this.props.height,
                        top: this.props.y,
                        left: this.props.x,
                        position: "absolute",
                        color: this.props.data.text_color,
                        textAlign: this.props.data.text_align,
                    }}
                    >
                        <div className={posText.replace(/[-]/g, "")} style={styleText}>
                            {text}
                        </div>
                    </div>

                );
            }
            else if (this.props.data.content) {
                let returnResult;

                this.props.data.content.forEach((res) => {
                    if (res.type === "video" || res.type === "webview") {

                        let regexLink = null;
                        let finalUrl = null;
                        if (res.type === "video") {
                            regexLink = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w]+\?v=|embed\/|v\/)?)([\w]+)(\S+)?$/gm;
                            finalUrl = res.url +
                                "&controls=" +
                                res.hasOptionTimeLine +
                                "&loop=" +
                                res.hasOptionLoop;
                        }
                        if (res.type === "webview") {
                            regexLink = /^(http|https):\/\/[^ "]+$/;
                            finalUrl = res.url;
                        }

                        if (res.url.match(regexLink)) {
                            returnResult = (
                                <iframe
                                    style={{
                                        position: "absolute",
                                        top: this.props.y,
                                        left: this.props.x,
                                    }}
                                    title="youtube"
                                    width={this.props.width}
                                    height={this.props.height}
                                    src={
                                        finalUrl
                                    }
                                    /*src={
                                        res.url +
                                        "&autoplay=1" +
                                        "&controls=" +
                                        res.hasOptionTimeLine +
                                        "&loop=" +
                                        res.hasOptionLoop
                                    }
                                    */
                                    frameBorder="0"
                                    //allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen={res.hasOptionFullScreen}
                                />
                            );
                        } else {
                            returnResult = (
                                <video
                                    crossOrigin={"use-credentials"}
                                    autoPlay={true}
                                    controls={res.hasOptionControls}
                                    width={this.props.width}
                                    height={this.props.height}
                                    style={{
                                        position: "absolute",
                                        top: this.props.y,
                                        left: this.props.x,
                                    }}
                                >
                                    {" "}
                                    <source src={buildShapperPath(res.url)} type="video/mp4"/>
                                </video>
                            );
                            console.log(" %c PAS YOUTUBE", 'background: #222; color: #bada55');
                        }
                    } else {
                        console.log(" %c Pas video", 'background: #222; color: #bada55');
                        returnResult = <p>{res.type}</p>;
                    }
                });

                return returnResult;
            }
            else {
                return (
                    <div
                        style={{
                            position: "absolute",
                            top: this.props.y,
                            left: this.props.x,
                            width: this.props.width,
                            height: this.props.height,
                            backgroundColor: this.props.data.color,
                            transform: rotation,
                        }}
                    />
                );
            }
        }
    }
}
