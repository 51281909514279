import isPlainObject from 'is-plain-object';
import {store} from "../../store/store";
import localForage from "localforage";
import Cookies from "universal-cookie";
import {pushToDataLayer} from "../../helpers/Tools";

const cookies = new Cookies();

/**
 * DECONNEXION
 */
export function logout() {

    pushToDataLayer({
        event: "logout",
        data: {
            eventCategory: "logout",
            eventAction: "logout",
            login: cookies.get('login'),
        }
    });

    cookies.remove('login', { path: "/" });
    cookies.remove('password', { path: "/" });
    cookies.remove('id_sso', { path: "/" });
    cookies.remove('code', { path: "/" });
    cookies.remove('access_token', { path: "/" });

    let pageId = store.getState().application.pageId;
    let RoutePathArray = store.getState().routes.RoutePathArray;

    localForage.clear().then(() => {
        window.localStorage.clear();
        window.location.href = pageId && RoutePathArray[pageId] ? RoutePathArray[pageId].url : "/" ;
    }).catch((e) => {
        console.log(e)
    })
}

export function filter(value, fn) {
    if (Array.isArray(value)) {
        return filterArray(value, fn);
    } else if (isPlainObject(value)) {
        return filterObject(value, fn);
    }

    return value;
}

function filterObject(obj, fn) {
    let newObj = {};
    let value;

    for (let key in obj) {
        if(obj.hasOwnProperty(key)){
            value = filter(obj[key], fn);

            if (fn.call(obj, value, key, obj)) {
                if (value !== obj[key] && !isCollection(value)) {
                    value = obj[key];
                }

                newObj[key] = value;
            }
        }
    }

    return newObj;
}

function filterArray(array, fn) {
    let filtered = [];

    array.forEach(function (value, index, array) {
        value = filter(value, fn);

        if (fn.call(array, value, index, array)) {
            if (value !== array[index] && !isCollection(value)) {
                value = array[index];
            }

            filtered.push(value);
        }
    });

    return filtered;
}

function isCollection(value) {
    return Array.isArray(value) || isPlainObject(value);
}
