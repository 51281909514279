import axios from "axios";
import * as Constants from "./api.constants";
import {store} from "../store/store";
import {baseApiUrl} from "./api";
import localForage from "localforage";
import {WS_GET_HISTORIC_RESPONSES, WS_GET_HISTORIC_VERSIONS} from "./api.constants";
import {pushToDataLayer} from "../helpers/Tools";

//Register
export const Register = async (props) => {
    return registerMobinaute(props)
};


export async function registerMobinaute(data) {

    try {

        //copie l'objet pour renommer au webservice
        if (data["Email"]){
            Object.defineProperty(data, "mail", Object.getOwnPropertyDescriptor(data, "Email"));
            delete data.Email;
        }

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_REGISTER_MOBINAUTE,
            headers: { "Content-Type": "multipart/form-data" },
            data: JSON.stringify(data),
        })
            .then(function (response) {
                //handle success
                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }

}

//Update Profil
export const SetProfilForm = async (props) => {
    try {
        if (props["Email"]){
            Object.defineProperty(props, "mail", Object.getOwnPropertyDescriptor(props, "Email"));
            delete props.Email;
        }

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_SET_RESPONSE_PROFIL_FORM,
            headers: { "Content-Type": "multipart/form-data" },
            data: JSON.stringify(props),
        })
            .then(function (response) {
                //handle success
                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

//ResponseDynamicForm
export const setResponseDynamicForm = async (props) => {
    try {
        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_SET_RESPONSE_DYNAMIC_FORM,
            data: props,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

export const getHistoricResponses = async (props) => {
    let token = store.getState().profil.mobinaute.accounts.shapper_token.access_token;
    let bodyFormData = new FormData();
    bodyFormData.set("formId", props.formId);
    try {
        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_HISTORIC_RESPONSES,
            data: bodyFormData,
            headers: { Authorization: `Bearer `+token },
        })
            .then(function (response) {
                //handle success
                console.log(response);
                return response;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

export const getHistoricVersions = async (props) => {
    let token = store.getState().profil.mobinaute.accounts.shapper_token.access_token;
    let bodyFormData = new FormData();
    bodyFormData.set("formId", props.formId);
    bodyFormData.set("formResponseIdentifier", props.formResponseIdentifier);
    try {
        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_HISTORIC_VERSIONS,
            data: bodyFormData,
            headers: { Authorization: `Bearer `+token },
        })
            .then(function (response) {
                //handle success
                console.log(response);
                return response;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

//PasswordLost
export const PasswordLost = async (props) => {
    try {
        let bodyFormData = new FormData();

        bodyFormData.set("mail", props);
        bodyFormData.set("appId", store.getState().application.getApp.id);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_PASSWORD_LOST,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                console.log(response)
                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

//uploadMediaStatic
export const uploadMediaStatic = async (props) => {
    try {
        //Api pour récupérer mes données voir https://alligator.io/react/axios-react/
        let bodyFormData = new FormData();

        bodyFormData.set("file_data", props);
        bodyFormData.set("file_height", 1);
        bodyFormData.set("file_width", 1);
        bodyFormData.set("app_module", "form");
        bodyFormData.set("app_id", store.getState().application.getApp.id);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_UPLOAD_MEDIA_STATIC,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(function (response) {
            //handle success
            return response.data
        });
    } catch (e) {
        console.log(e);
    }
};
