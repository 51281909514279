import React from "react";
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Avatar,
    Button, IconButton, Divider,
    TextField, List, ListItem, ListItemAvatar, ListItemText,
    Dialog, DialogTitle, DialogContent
} from "@material-ui/core";

import {arrayFilter, choiceImage, isResponsive, parserContent} from "../../../helpers/Tools";
import BreadCrumbs from "../../../components/BreadCrumbs";
import FlatList from 'flatlist-react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import OpenItem from "../../../helpers/OpenItem";
import { useState } from "react";

import {baseApiUrl} from "../../../api/api";
import {store} from "../../../store/store";

class SocialFeed extends React.Component {

    render() {
        const dyn_string = this.props.t
        let list = arrayFilter(this.props.nodes, 'is_visible', '1');

        if (isResponsive()) {
            return (
                <ul style={{
                    padding: 0,
                    listStyle: "none",
                    gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
                    paddingInlineStart: "20px",
                    paddingInlineEnd: "20px"
                }}>
                    <FlatList
                        list={list}
                        renderItem={this.renderCategory}
                        renderWhenEmpty={() => <h4
                            style={{marginTop: "70px", textAlign: "center"}}>{dyn_string('g_label_no_result')}</h4>}
                        display={{
                            grid: false,
                            gridGap: "20px",
                        }}
                        style={{listStyle: "none", backgroundColor: '#ffffff'}}
                    />
                </ul>
            );
        } else {
            return (
                <div>
                    <BreadCrumbs id={this.props.dataPage.id} style={{marginBottom: "20px"}}/>
                    <ul style={{
                        gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
                        paddingInlineStart: "20px",
                        paddingInlineEnd: "20px"
                    }}>
                        <FlatList
                            list={list}
                            renderItem={this.renderCategory}
                            renderWhenEmpty={() => <h4
                                style={{marginTop: "70px", textAlign: "center", backgroundColor: '#ffffff'}}>{dyn_string('g_label_no_result')}</h4>}
                            display={{
                                grid: false,
                                gridGap: "40px",
                            }}
                        />
                    </ul>
                </div>
            );
        }
    }

    renderCategory = (tile, key) => {
        const [expanded, setExpanded] = useState(false);
        const [open, setOpen] = useState(false);
        const [comments, setComments] = useState([]);
        const [commentText, setCommentText] = useState('');
        const [like, setLike] = useState('');

        let state = store.getState();

        const handleClose = () => setOpen(false);

        const text = tile.content;
        //const prenomnom = tile.prenom + " " + tile.nom;
        const prenomnom = "Guillaume Lepage";
        //const timeAgo = tile.timeAgo;
        const timeAgo = "Il y a 2 heures";

        const handleOpen = async () => {
            setOpen(true);
            try {
                const response = await fetch(baseApiUrl + '/getInteractionsByContentId', {
                    method: 'POST', // Requête POST, comme dans le curl
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({
                        'appId': state.application.getApp.id,
                        'mobinauteId': parseInt(state.profil.mobinaute.id, 10),
                        'contentId': tile.id, // ID du contenu
                        'offset': '0',
                        'limit': '100'
                    })
                });

                const data = await response.json();

                if (!data.success) {
                    throw new Error('Erreur lors de la récupération des commentaires');
                }


                const comments = data.result.comments;

                console.log(comments);

                setComments(comments); // Assurez-vous que `data` contient les commentaires sous la forme attendue
            } catch (error) {
                console.error('Erreur lors du chargement des commentaires:', error);
            }
        };

        const handleLike = async () => {
            setLike(true);
            try {
                const response = await fetch(baseApiUrl + '/setInteractionForContentId', {
                    method: 'POST', // Méthode POST
                    body: new URLSearchParams({
                        'appId': state.application.getApp.id,
                        'mobinauteId': parseInt(state.profil.mobinaute.id, 10),
                        'contentId': tile.id, // ID du contenu
                        'type': 'reaction', // Type d'interaction
                        'value': "1" //
                    })
                });

                const data = await response.json();

                if (!data.success) {
                    throw new Error('Erreur lors du like');
                }
            } catch (error) {
                console.error('Erreur lors du chargement des commentaires:', error);
            }
        };

        const handleSubmitComment = async (commentText) => {
            try {
                const response = await fetch(baseApiUrl + '/setInteractionForContentId', {
                    method: 'POST', // Méthode POST
                    body: new URLSearchParams({
                        'appId': state.application.getApp.id,
                        'mobinauteId': parseInt(state.profil.mobinaute.id, 10),
                        'contentId': tile.id, // ID du contenu
                        'type': 'comment', // Type d'interaction
                        'value': commentText // Le texte du commentaire
                    })
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de l\'envoi du commentaire');
                }

                const data = await response.json();
                console.log('Commentaire envoyé avec succès:', data);
                // Optionnellement, tu peux recharger les commentaires après avoir ajouté un nouveau commentaire
                handleOpen(); // Récupère les nouveaux commentaires après l'envoi
            } catch (error) {
                console.error('Erreur lors de l\'envoi du commentaire:', error);
            }
        };

        return (
                <Card key={key} sx={{ margin: "auto", borderRadius: 3, boxShadow: 3 }}>
                    {/* Header */}
                    <CardContent sx={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "auto" }}>
                            <Avatar src={choiceImage(tile)} alt="Profile" sx={{ width: 56, height: 56, mr: 2 }} />
                            <div style={{ paddingLeft: 15 }}>
                                <Typography variant="h6" sx={{ mr: 1 }}>{prenomnom}</Typography>
                                <Typography variant="body2" color="textSecondary">{timeAgo}</Typography>
                            </div>
                        </div>
                    </CardContent>

                    {/* Image d'illustration */}
                    <CardMedia component="img" image={choiceImage(tile)} alt="Illustration" style={{ padding : 10, maxHeight: 400 }} />

                    {/* Contenu */}

                    <CardContent>
                        {/* Titre et sous-titre */}
                        <Typography variant="h5" gutterBottom>{tile.title}</Typography>
                        <Typography variant="subtitle1" color="textSecondary">{tile.subtitle}</Typography>

                        {/* Texte de publication */}
                        <Typography variant="body1" paragraph>
                            {/*expanded ? text : `${text.substring(0, 80)}...`*/}
                            {parserContent(text)}
                        </Typography>
                        {!expanded && (
                            <Button onClick={() => setExpanded(true)} color="primary">Voir plus</Button>
                        )}
                    </CardContent>

                    <Divider />

                    {/* Boutons */}
                    <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
                        <IconButton color="error" onClick={handleLike}>
                            J'aime (4)
                        </IconButton>
                        <IconButton color="primary" onClick={handleOpen}>
                            Commenter (123)
                        </IconButton>
                    </CardContent>
                    {/* Section des commentaires */}
                    {/* Popup de commentaires */}
                    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                        <DialogTitle>Commentaires</DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Écrire un commentaire..."
                                value={commentText}
                                onChange={(e) => setCommentText(e.target.value)}
                                sx={{ marginBottom: 2 }}
                            />
                            <Button
                                onClick={() => handleSubmitComment(commentText)}
                                color="primary"
                                disabled={!commentText.trim()}
                            >
                                Envoyer
                            </Button>
                            {/* Liste des commentaires */}
                            <List>
                                {comments.map((comment, index) => (
                                    <ListItem key={index} alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar src={comment.avatar} alt={comment.lastname} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={comment.firstname + ' ' + comment.lastname}
                                            secondary={comment.commentaire}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </DialogContent>
                    </Dialog>
                </Card>
        );
    }
}

const mapStateToProps = (props) => ({
    styles: props.style
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps()))(SocialFeed);
