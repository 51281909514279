import React from "react";
import Parser from "html-react-parser";
import {handleConnector} from "./Category.functions";
import {bindActionCreators} from "redux";
import {requestGetConnectorsData} from "../../actions";
import {connect} from "react-redux";
import {ShapperLoader} from "../../components/ShapperLoader/ShapperLoader";
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import Category from "./Category"
import CategoryListMap from "./ListMap/ListMap";
import CategoryProduct from "./CategoryProduct/CategoryProduct";
import AnnounceCategory from "../Announce/Announce.List";
import {CategoryList} from "./List/Category.List";
import {FormControl, Select, TextField} from "@material-ui/core";
import CategoryDistance from "./CategoryDistance/CategoryDistance";
import CategoryGlossary from "./CategoryGlossary/CategoryGlossary";
import CategoryGrid from "./CategoryGrid/CategoryGrid";
import SocialFeed from "./SocialFeed/SocialFeed";
import CategoryBanner from "./CategoryBanner/CategoryBanner";
import moment from 'moment';
import Planning from "../Planning/Planning";

class CategoryMain extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.dataPage.connector_id) {
            if (this.props.connectors.success) {
                this.state = {
                    loading: true,
                    loading_update: false,
                    input_value: '',
                    dataToPass: null,
                    filter_rubric: ''
                };
            } else {
                this.state = {
                    loading: false,
                    loading_update: false,
                    input_value: '',
                    dataToPass: null,
                    filter_rubric: ''
                };
            }
        } else {
            this.state = {
                loading: false,
                loading_update: false,
                input_value: '',
                dataToPass: null,
                filter_rubric: ''
            };
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    async componentDidMount() {
        window.scrollTo(0, 0);

        if (this.props.dataPage.connector_id) {
            if (this.props.connectors.success) {
                handleConnector(this.props.connectors.result, this.props.dataPage, this.props.variableStorage).then(isLoading => {
                    this.setState({loading: isLoading});
                })
            }
        }
    }

    async componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.dataPage.connector_id) {
            if (this.props.connectors.success) {
                if (Object.keys(prevProps.variableStorage).length === 0) {
                    if (Object.keys(this.props.variableStorage).length > 0) {
                        this.setState({loading_update: true});
                        handleConnector(this.props.connectors.result, this.props.dataPage, this.props.variableStorage).then(isLoading => {
                            this.setState({loading_update: isLoading});
                        })
                    }
                } else {
                    if (Object.keys(this.props.variableStorage).length > 0) {
                        Object.entries(prevProps.variableStorage).forEach(([key, data]) => {
                            if (this.props.variableStorage[key]) {
                                if (this.props.variableStorage[key] !== data) {
                                    this.setState({loading_update: true});
                                    handleConnector(this.props.connectors.result, this.props.dataPage, this.props.variableStorage).then(isLoading => {
                                        this.setState({loading_update: isLoading});
                                    })
                                }
                            }
                        })
                    }
                }
            }
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;

        this.setState({input_value: value});
        this.setState({filter_rubric: ''});
    }

    handleSelectChange = (event) => {
        this.setState({filter_rubric: event.target.value});
        this.setState({input_value: event.target.value});
    };

    render() {
        const dyn_string = this.props.t;
        let stringWhereSearch = "";
        let full_input = this.state.input_value.split(" ");
        let full_regex = [];
        let isHere = false;
        full_input.forEach((content) => {
            if (content) {
                full_regex.push(new RegExp(content, "i"));
            }
        });

        let nodes = this.props.dataPage.nodes;

        if (this.state.input_value !== '') {
            nodes = [];
            this.props.dataPage.nodes.forEach((content) => {
                for (let regex of full_regex) {
                    if (stringWhereSearch.concat(' ', content.title, content.subtitle, content.content, content.keyword).search(regex) !== -1) {
                        isHere = true;
                    } else {
                        isHere = false;
                        break;
                    }
                }

                if (isHere) {
                    nodes.push(content);
                }
            });
        }

        if (this.state.loading || this.state.loading_update) {
            return <ShapperLoader inner/>
        } else {
            if (!this.props.dataPage.nodes) {
                return <h1>{Parser(this.props.dataPage.content)}</h1>;
            } else if (this.props.dataPage.nodes.length === 0) {
                return (
                    <div style={{textAlign: "center"}}>
                        <h1 style={{color: '#888888'}}>Rubrique vide</h1>
                    </div>
                );
            } else {
                if (this.props.dataPage.type === "list_map") {
                    if (this.props.dataPage.filter_rubric == 1) {
                        return (
                            <div>
                                <div style={{display: "flex", marginBottom: "30px"}}>
                                    <TextField
                                        aria-required={true}
                                        variant="outlined"
                                        margin="normal"
                                        style={this.props.dataPage.filter_suggestion.length > 0 ? {width: "90%"} : {width: "100%"}}
                                        id="search"
                                        name="search"
                                        type="text"
                                        label={dyn_string('search_title_search')}
                                        placeholder={dyn_string('search_placeholder_your_search')}
                                        onChange={this.handleChange}
                                        value={this.state.input_value}
                                    />
                                    {
                                        this.props.dataPage.filter_suggestion.length > 0 &&
                                        <FormControl variant="outlined"
                                                     style={{width: "10%", marginTop: "16px", marginBottom: "8px"}}>
                                            <Select
                                                native
                                                onChange={this.handleSelectChange}
                                                label={"Mot-clés"}
                                                value={this.state.filter_rubric}
                                                inputProps={{
                                                    name: 'filter_rubric',
                                                    id: 'outlined-filter_rubric-native-simple',
                                                }}
                                            >
                                                <option key={"key"} value={""}>{"Mots-clés.."}</option>
                                                {this.props.dataPage.filter_suggestion.map((res, key) =>
                                                    <option key={key} value={res}>{res}</option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                                <CategoryListMap dataPage={this.props.dataPage} nodes={nodes}/>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <CategoryListMap dataPage={this.props.dataPage} nodes={nodes}/>
                            </div>
                        )
                    }
                }

                if (this.props.dataPage.type === "category_product") {
                    if (this.props.dataPage.filter_rubric == 1) {
                        return (
                            <div>
                                <div style={{display: "flex", marginBottom: "30px"}}>
                                    <TextField
                                        aria-required={true}
                                        variant="outlined"
                                        margin="normal"
                                        style={this.props.dataPage.filter_suggestion.length > 0 ? {width: "90%"} : {width: "100%"}}
                                        id="search"
                                        name="search"
                                        type="text"
                                        label={dyn_string('search_title_search')}
                                        placeholder={dyn_string('search_placeholder_your_search')}
                                        onChange={this.handleChange}
                                        value={this.state.input_value}
                                    />
                                    {
                                        this.props.dataPage.filter_suggestion.length > 0 &&
                                        <FormControl variant="outlined"
                                                     style={{width: "10%", marginTop: "16px", marginBottom: "8px"}}>
                                            <Select
                                                native
                                                onChange={this.handleSelectChange}
                                                label={"Mot-clés"}
                                                value={this.state.filter_rubric}
                                                inputProps={{
                                                    name: 'filter_rubric',
                                                    id: 'outlined-filter_rubric-native-simple',
                                                }}
                                            >
                                                <option key={"key"} value={""}>{"Mots-clés.."}</option>
                                                {this.props.dataPage.filter_suggestion.map((res, key) =>
                                                    <option key={key} value={res}>{res}</option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                                <CategoryProduct dataPage={this.props.dataPage} nodes={nodes}/>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div>
                                <CategoryProduct dataPage={this.props.dataPage} nodes={nodes}/>
                            </div>
                        )
                    }
                }

                if (this.props.dataPage.type === "list_announce") {
                    if (this.props.dataPage.filter_rubric == 1) {
                        return (
                            <div>
                                <div style={{display: "flex", marginBottom: "30px"}}>
                                    <TextField
                                        aria-required={true}
                                        variant="outlined"
                                        margin="normal"
                                        style={this.props.dataPage.filter_suggestion.length > 0 ? {width: "90%"} : {width: "100%"}}
                                        id="search"
                                        name="search"
                                        type="text"
                                        label={dyn_string('search_title_search')}
                                        placeholder={dyn_string('search_placeholder_your_search')}
                                        onChange={this.handleChange}
                                        value={this.state.input_value}
                                    />
                                    {
                                        this.props.dataPage.filter_suggestion.length > 0 &&
                                        <FormControl variant="outlined"
                                                     style={{width: "10%", marginTop: "16px", marginBottom: "8px"}}>
                                            <Select
                                                native
                                                onChange={this.handleSelectChange}
                                                label={"Mot-clés"}
                                                value={this.state.filter_rubric}
                                                inputProps={{
                                                    name: 'filter_rubric',
                                                    id: 'outlined-filter_rubric-native-simple',
                                                }}
                                            >
                                                <option key={"key"} value={""}>{"Mots-clés.."}</option>
                                                {this.props.dataPage.filter_suggestion.map((res, key) =>
                                                    <option key={key} value={res}>{res}</option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                                <AnnounceCategory dataPage={this.props.dataPage}/>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <AnnounceCategory dataPage={this.props.dataPage}/>
                            </div>
                        )
                    }
                }

                if (this.props.dataPage.type === "category") {

                    if (this.props.dataPage.category_type === "list_title" || this.props.dataPage.category_type === "list_subtitle") {
                        if (this.props.dataPage.filter_rubric == 1) {
                            return (
                                <div>
                                    <TextField
                                        aria-required={true}
                                        variant="outlined"
                                        margin="normal"
                                        style={this.props.dataPage.filter_suggestion.length > 0 ? {width: "90%"} : {width: "100%"}}
                                        id="search"
                                        name="search"
                                        type="text"
                                        label={dyn_string('search_title_search')}
                                        placeholder={dyn_string('search_placeholder_your_search')}
                                        onChange={this.handleChange}
                                        value={this.state.input_value}
                                    />
                                    {
                                        this.props.dataPage.filter_suggestion.length > 0 &&
                                        <FormControl variant="outlined"
                                                     style={{width: "10%", marginTop: "16px", marginBottom: "8px"}}>
                                            <Select
                                                native
                                                onChange={this.handleSelectChange}
                                                label={"Mot-clés"}
                                                value={this.state.filter_rubric}
                                                inputProps={{
                                                    name: 'filter_rubric',
                                                    id: 'outlined-filter_rubric-native-simple',
                                                }}
                                            >
                                                <option key={"key"} value={""}>{"Mots-clés.."}</option>
                                                {this.props.dataPage.filter_suggestion.map((res, key) =>
                                                    <option key={key} value={res}>{res}</option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    }
                                    <CategoryList dataPage={this.props.dataPage} nodes={nodes}/>
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    <CategoryList dataPage={this.props.dataPage} nodes={nodes}/>
                                </div>
                            )
                        }
                    }

                    if (this.props.dataPage.category_type === "list_distance") {
                        if (this.props.dataPage.filter_rubric == 1) {
                            return (
                                <div>
                                    <TextField
                                        aria-required={true}
                                        variant="outlined"
                                        margin="normal"
                                        style={this.props.dataPage.filter_suggestion.length > 0 ? {width: "90%"} : {width: "100%"}}
                                        id="search"
                                        name="search"
                                        type="text"
                                        label={dyn_string('search_title_search')}
                                        placeholder={dyn_string('search_placeholder_your_search')}
                                        onChange={this.handleChange}
                                        value={this.state.input_value}
                                    />
                                    {
                                        this.props.dataPage.filter_suggestion.length > 0 &&
                                        <FormControl variant="outlined"
                                                     style={{width: "10%", marginTop: "16px", marginBottom: "8px"}}>
                                            <Select
                                                native
                                                onChange={this.handleSelectChange}
                                                label={"Mot-clés"}
                                                value={this.state.filter_rubric}
                                                inputProps={{
                                                    name: 'filter_rubric',
                                                    id: 'outlined-filter_rubric-native-simple',
                                                }}
                                            >
                                                <option key={"key"} value={""}>{"Mots-clés.."}</option>
                                                {this.props.dataPage.filter_suggestion.map((res, key) =>
                                                    <option key={key} value={res}>{res}</option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    }
                                    <CategoryDistance dataPage={this.props.dataPage} nodes={nodes}/>
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    <CategoryDistance dataPage={this.props.dataPage} nodes={nodes}/>
                                </div>
                            )
                        }
                    }

                    if (this.props.dataPage.category_type === "glossary") {
                        if (this.props.dataPage.filter_rubric == 1) {
                            return (
                                <div>
                                    <TextField
                                        aria-required={true}
                                        variant="outlined"
                                        margin="normal"
                                        style={this.props.dataPage.filter_suggestion.length > 0 ? {width: "90%"} : {width: "100%"}}
                                        id="search"
                                        name="search"
                                        type="text"
                                        label={dyn_string('search_title_search')}
                                        placeholder={dyn_string('search_placeholder_your_search')}
                                        onChange={this.handleChange}
                                        value={this.state.input_value}
                                    />
                                    {
                                        this.props.dataPage.filter_suggestion.length > 0 &&
                                        <FormControl variant="outlined"
                                                     style={{width: "10%", marginTop: "16px", marginBottom: "8px"}}>
                                            <Select
                                                native
                                                onChange={this.handleSelectChange}
                                                label={"Mot-clés"}
                                                value={this.state.filter_rubric}
                                                inputProps={{
                                                    name: 'filter_rubric',
                                                    id: 'outlined-filter_rubric-native-simple',
                                                }}
                                            >
                                                <option key={"key"} value={""}>{"Mots-clés.."}</option>
                                                {this.props.dataPage.filter_suggestion.map((res, key) =>
                                                    <option key={key} value={res}>{res}</option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    }
                                    <CategoryGlossary dataPage={this.props.dataPage} nodes={nodes}/>
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    <CategoryGlossary dataPage={this.props.dataPage} nodes={nodes}/>
                                </div>
                            )
                        }
                    }

                    if (this.props.dataPage.category_type === "grid_columns") {
                        if (this.props.dataPage.filter_rubric == 1) {
                            return (
                                <div>
                                    <div style={{display: "flex", marginBottom: "30px"}}>
                                        <TextField
                                            aria-required={true}
                                            variant="outlined"
                                            margin="normal"
                                            style={this.props.dataPage.filter_suggestion.length > 0 ? {width: "90%"} : {width: "100%"}}
                                            id="search"
                                            name="search"
                                            type="text"
                                            label={dyn_string('search_title_search')}
                                            placeholder={dyn_string('search_placeholder_your_search')}
                                            onChange={this.handleChange}
                                            value={this.state.input_value}
                                        />
                                        {
                                            this.props.dataPage.filter_suggestion.length > 0 &&
                                            <FormControl variant="outlined"
                                                         style={{width: "10%", marginTop: "16px", marginBottom: "8px"}}>
                                                <Select
                                                    native
                                                    onChange={this.handleSelectChange}
                                                    label={"Mot-clés"}
                                                    value={this.state.filter_rubric}
                                                    inputProps={{
                                                        name: 'filter_rubric',
                                                        id: 'outlined-filter_rubric-native-simple',
                                                    }}
                                                >
                                                    <option key={"key"} value={""}>{"Mots-clés.."}</option>
                                                    {this.props.dataPage.filter_suggestion.map((res, key) =>
                                                        <option key={key} value={res}>{res}</option>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        }
                                    </div>
                                    <CategoryGrid dataPage={this.props.dataPage} nodes={nodes}/>
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    <CategoryGrid dataPage={this.props.dataPage} nodes={nodes}/>
                                </div>
                            )
                        }
                    }

                    if (this.props.dataPage.category_type === "news_feed"){
                        if (this.props.dataPage.filter_rubric == 1) {
                            return (
                                <div>
                                    <div style={{display: "flex", marginBottom: "30px"}}>
                                        <TextField
                                            aria-required={true}
                                            variant="outlined"
                                            margin="normal"
                                            style={this.props.dataPage.filter_suggestion.length > 0 ? {width: "90%"} : {width: "100%"}}
                                            id="search"
                                            name="search"
                                            type="text"
                                            label={dyn_string('search_title_search')}
                                            placeholder={dyn_string('search_placeholder_your_search')}
                                            onChange={this.handleChange}
                                            value={this.state.input_value}
                                        />
                                        {
                                            this.props.dataPage.filter_suggestion.length > 0 &&
                                            <FormControl variant="outlined"
                                                         style={{width: "10%", marginTop: "16px", marginBottom: "8px"}}>
                                                <Select
                                                    native
                                                    onChange={this.handleSelectChange}
                                                    label={"Mot-clés"}
                                                    value={this.state.filter_rubric}
                                                    inputProps={{
                                                        name: 'filter_rubric',
                                                        id: 'outlined-filter_rubric-native-simple',
                                                    }}
                                                >
                                                    <option key={"key"} value={""}>{"Mots-clés.."}</option>
                                                    {this.props.dataPage.filter_suggestion.map((res, key) =>
                                                        <option key={key} value={res}>{res}</option>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        }
                                    </div>
                                    <div style={{display:'contents'}}>
                                        <SocialFeed dataPage={this.props.dataPage} nodes={nodes}/>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div style={{display:'contents'}}>
                                    <SocialFeed dataPage={this.props.dataPage} nodes={nodes}/>
                                </div>
                            )
                        }
                    }

                    // A décommenter si on veut réactiver l'affichage bannière
                    /*if (this.props.dataPage.category_type === "list_full_preview") {
                        if (this.props.dataPage.filter_rubric == 1) {
                            return (
                                <div>
                                    <div style={{display: "flex", marginBottom: "30px"}}>
                                        <TextField
                                            aria-required={true}
                                            variant="outlined"
                                            margin="normal"
                                            style={this.props.dataPage.filter_suggestion.length > 0 ? {width: "90%"} : {width: "100%"}}
                                            id="search"
                                            name="search"
                                            type="text"
                                            label={dyn_string('search_title_search')}
                                            placeholder={dyn_string('search_placeholder_your_search')}
                                            onChange={this.handleChange}
                                            value={this.state.input_value}
                                        />
                                        {
                                            this.props.dataPage.filter_suggestion.length > 0 &&
                                            <FormControl variant="outlined"
                                                         style={{width: "10%", marginTop: "16px", marginBottom: "8px"}}>
                                                <Select
                                                    native
                                                    onChange={this.handleSelectChange}
                                                    label={"Mot-clés"}
                                                    value={this.state.filter_rubric}
                                                    inputProps={{
                                                        name: 'filter_rubric',
                                                        id: 'outlined-filter_rubric-native-simple',
                                                    }}
                                                >
                                                    <option key={"key"} value={""}>{"Mots-clés.."}</option>
                                                    {this.props.dataPage.filter_suggestion.map((res, key) =>
                                                        <option key={key} value={res}>{res}</option>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        }
                                    </div>
                                    <CategoryBanner dataPage={this.props.dataPage} nodes={nodes}/>
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    <CategoryBanner dataPage={this.props.dataPage} nodes={nodes}/>
                                </div>
                            )
                        }
                    }*/

                    if (this.props.dataPage.category_type === "planning" || this.props.dataPage.category_type === "planning_daily") {
                        const events_for_planning = [];
                        const events_ids = [];
                        const plannings = []

                        nodes.map((node) => {
                            if (node.event_start !== 'undefined') {
                                const event = {
                                    title: node.title,
                                    subtitle: node.subtitle,
                                    id: node.id,
                                    startDate: moment(node.event_start, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                                    endDate: moment(node.event_end, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                                    planning: null,
                                    availability: null,
                                    places: null,
                                    organizer_mobinaute_id: null,
                                    description: node.description,
                                    startDateString: moment(node.event_start, 'YYYY-MM-DD').utc().format('YYYY-MM-DD'),
                                    endDateString: moment(node.event_end, 'YYYY-MM-DD').utc().format('YYYY-MM-DD'),
                                    startTimeString: moment(node.event_start, 'HH:mm:ss').utc().format('HH:mm:ss.SSS'),
                                    endTimeString: moment(node.event_end, 'HH:mm:ss').utc().format('HH:mm:ss.SSS'),
                                    address_city: node.address_city,
                                    address_country: node.address_country,
                                    address_street1: node.address_street1,
                                    address_street2: node.address_street2,
                                    address_zip: node.address_zip
                                }
                                events_ids.push(node.id)
                                events_for_planning.push(event);
                            }
                        })
                        const planning = {
                            id: 1,
                            title: this.props.dataPage.title,
                            participationOpen: 0,
                            type: "event",
                            event_visibility: null,
                            event_validation: null,
                            event_simultaneously: null,
                            address: null,
                            color: "#00fa92",
                            evt_planning_id: null,
                            form_id: null,
                            form_create_id: null,
                            display_type: null,
                            groups: null,
                            form: null,
                            form_event_participation: null,
                            form_event_creation: null,
                            events_id: events_ids,
                            services_id: null
                        }
                        plannings.push(planning)
                        this.props.dataPage.planning_id = 1;

                        if (this.props.dataPage.filter_rubric == 1) {
                            return (
                                <div>
                                    <div style={{display: "flex", marginBottom: "30px"}}>
                                        <TextField
                                            aria-required={true}
                                            variant="outlined"
                                            margin="normal"
                                            style={this.props.dataPage.filter_suggestion.length > 0 ? {width: "90%"} : {width: "100%"}}
                                            id="search"
                                            name="search"
                                            type="text"
                                            label={dyn_string('search_title_search')}
                                            placeholder={dyn_string('search_placeholder_your_search')}
                                            onChange={this.handleChange}
                                            value={this.state.input_value}
                                        />
                                        {
                                            this.props.dataPage.filter_suggestion.length > 0 &&
                                            <FormControl variant="outlined"
                                                         style={{width: "10%", marginTop: "16px", marginBottom: "8px"}}>
                                                <Select
                                                    native
                                                    onChange={this.handleSelectChange}
                                                    label={"Mot-clés"}
                                                    value={this.state.filter_rubric}
                                                    inputProps={{
                                                        name: 'filter_rubric',
                                                        id: 'outlined-filter_rubric-native-simple',
                                                    }}
                                                >
                                                    <option key={"key"} value={""}>{"Mots-clés.."}</option>
                                                    {this.props.dataPage.filter_suggestion.map((res, key) =>
                                                        <option key={key} value={res}>{res}</option>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        }
                                    </div>
                                    <Planning events={Array.from(new Set(events_for_planning))}
                                              dataPage={this.props.dataPage} plannings={plannings} isCategory={true}/>
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    <Planning events={Array.from(new Set(events_for_planning))}
                                              dataPage={this.props.dataPage} plannings={plannings} isCategory={true}/>
                                </div>
                            )
                        }
                    }

                    if (this.props.dataPage.filter_rubric == 1) {
                        return (
                            <div>
                                <div style={{display: "flex", marginBottom: "30px"}}>
                                    <TextField
                                        aria-required={true}
                                        variant="outlined"
                                        margin="normal"
                                        style={this.props.dataPage.filter_suggestion.length > 0 ? {width: "90%"} : {width: "100%"}}
                                        id="search"
                                        name="search"
                                        type="text"
                                        label={dyn_string('search_title_search')}
                                        placeholder={dyn_string('search_placeholder_your_search')}
                                        onChange={this.handleChange}
                                        value={this.state.input_value}
                                    />
                                    {
                                        this.props.dataPage.filter_suggestion.length > 0 &&
                                        <FormControl variant="outlined"
                                                     style={{width: "10%", marginTop: "16px", marginBottom: "8px"}}>
                                            <Select
                                                native
                                                onChange={this.handleSelectChange}
                                                label={"Mot-clés"}
                                                value={this.state.filter_rubric}
                                                inputProps={{
                                                    name: 'filter_rubric',
                                                    id: 'outlined-filter_rubric-native-simple',
                                                }}
                                            >
                                                <option key={"key"} value={""}>{"Mots-clés.."}</option>
                                                {this.props.dataPage.filter_suggestion.map((res, key) =>
                                                    <option key={key} value={res}>{res}</option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                                <Category dataPage={this.props.dataPage} nodes={nodes} isGrid={false}/>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <Category dataPage={this.props.dataPage} nodes={nodes} isGrid={false}/>
                            </div>
                        )
                    }
                }

                return '';
            }
        }
    }
}

const mapStateToProps = (state) => ({
    connectors: state.connectors,
    variableStorage: state.variableStorage,
    styles: state.style,
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestGetConnectorsData,
        },
        dispatch
    );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps()))(CategoryMain);
