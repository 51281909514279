import React from "react";
import {
    Card,
    CardMedia,
    Typography
} from "@material-ui/core";
import {arrayFilter, choiceImage, isResponsive} from "../../../helpers/Tools";
import BreadCrumbs from "../../../components/BreadCrumbs";
import FlatList from 'flatlist-react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import OpenItem from "../../../helpers/OpenItem";

class CategoryGrid extends React.Component {

    render() {
        const dyn_string = this.props.t
        let list = arrayFilter(this.props.nodes, 'is_visible', '1');

        if (isResponsive()) {
            return (
                <ul style={{
                    padding: 0,
                    listStyle: "none",
                    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                    paddingInlineStart: "20px",
                    paddingInlineEnd: "20px"
                }}>
                    <FlatList
                        list={list}
                        renderItem={this.renderCategory}
                        renderWhenEmpty={() => <h4
                            style={{marginTop: "70px", textAlign: "center"}}>{dyn_string('g_label_no_result')}</h4>}
                        display={{
                            grid: true,
                            gridGap: "20px",
                            minColumnWidth: "200px",
                        }}
                        style={{listStyle: "none", backgroundColor: '#ffffff'}}
                    />
                </ul>
            );
        } else {
            return (
                <div>
                    <BreadCrumbs id={this.props.dataPage.id} style={{marginBottom: "20px"}}/>
                    <ul style={{
                        gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
                        paddingInlineStart: "20px",
                        paddingInlineEnd: "20px"
                    }}>
                        <FlatList
                            list={list}
                            renderItem={this.renderCategory}
                            renderWhenEmpty={() => <h4
                                style={{marginTop: "70px", textAlign: "center", backgroundColor: '#ffffff'}}>{dyn_string('g_label_no_result')}</h4>}
                            display={{
                                grid: true,
                                gridGap: "40px",
                                minColumnWidth: "350px",
                            }}
                        />
                    </ul>
                </div>
            );
        }
    }

    renderCategory = (tile, key) => {

        return (
            <OpenItem
                key={key}
                contentToRedirect={tile.id}
                isFor={'category'}
            >
                <Card style={{
                    position: 'relative',
                    width: 300,
                    height: 300,
                    overflow: 'hidden'
                }}>
                    <CardMedia
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}
                        component="img"
                        alt={tile.title}
                        title={tile.title}
                        image={choiceImage(tile)}
                    />
                    <Typography variant="h6" style={{
                        position: 'absolute',
                        top: 10,
                        left: 10,
                        color: 'white',
                        fontSize: 20,
                        fontWeight: 'bold',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
                    }}>
                        {tile.title}
                    </Typography>
                </Card>
            </OpenItem>
        );
    }
}

const mapStateToProps = (props) => ({
    styles: props.style
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps()))(CategoryGrid);
